<template>
  <layout-modal
    :classes="classes"
    visible
    @close="close">

    <!-- loading -->
    <template v-if="loading">
      <div class="layout-modal__loader">
        <ui-loader />
      </div>
    </template>

    <!-- Product:Default -->
    <catalog-product-default
      v-if="isProduct && !loading"
      class="view-catalog-product__content"
      v-model="item"
      @close="close"
      />

    <!-- Product:Bundle -->
    <catalog-product-bundle
      v-if="isBundle && !loading"
      class="view-catalog-product__content"
      v-model="item"
      @close="close"
      />

    <!-- Product:GiftVoucher-->
    <catalog-product-gift-voucher
      v-if="isGiftVoucher && !loading"
      class="view-catalog-product__content"
      v-model="item"
      @close="close"
    />
  </layout-modal>
</template>

<script>
import { mapActions, mapMutations, mapState } from 'vuex'
import MixinCurrency from '@/helpers/currency'

import LayoutModal from '@/components/layouts/modal'
import CatalogProductBundle from '@/modules/catalog/components/product/bundle'
import CatalogProductDefault from '@/modules/catalog/components/product/default'
import CatalogProductGiftVoucher from '@/modules/catalog/components/product/gift-voucher'

import MixinMultishop from '@/helpers/multishop'

export default {
  name: 'CatalogProductView',

  components: {
    CatalogProductBundle,
    CatalogProductDefault,
    CatalogProductGiftVoucher,

    LayoutModal,
  },

  inject: ['$cart'],

  mixins: [
    MixinCurrency,
    MixinMultishop
  ],

  data() {
    return {
      loading: true,
      item: null,
    }
  },

  computed: {
    ...mapState({
      categories: state => state['sayl-front.catalog'].categories,
      options: state => state['sayl-front.catalog-product'].product.options,
      product: state => state['sayl-front.catalog-product'].product,
    }),

    classes() {
      return {
        'view-catalog-product': true
      }
    },

    isBundle() {
      return this.item && this.item.product.type === 'bundle'
    },

    isProduct() {
      return this.item && this.item.product.type === 'default'
    },

    isGiftVoucher() {
      return this.item && this.item.product.type === 'gift_voucher'
    }
  },

  methods: {
    ...mapActions({
      view: 'sayl-front.catalog-product/view',
    }),

    ...mapMutations({
      setProduct: 'sayl-front.catalog-product/setProduct'
    }),

    close() {
      if(this.isMultiShopsEnabled) {
        return this.$router.replace({ name:'sayl-front-catalog.catalog' }).catch(() => {})
      }

      this.$router.replace({ name:'sayl-front-catalog.catalog' }).catch(() => {})
    },

    onKeyDown(event) {
      switch(event.keyCode) {
        case 27:
          this.close();
          break;
      }
    },
  },

  mounted() {
    let productId = this.$route.params.product;
    if (!productId) {
      return;
    }

    document.addEventListener('keydown', this.onKeyDown.bind(this));

    this.$bus.$emit('injekt.sp.product', productId);

    this.loading = true;
    this.view(productId)
      .then(() => this.$cart.createItem({ product: this.product, options: this.options, shopId: this.$basil.get(this.subshop, 'id', null) }))
      .then((item) => {
        this.item = item;
        if(this.item.product.type === 'default') {
          this.item.variant = this.item.product._variant = this.item.product.variants.find((v) => v.available === true)
        }
        this.loading = false
      })
      .catch((e) => $console.error(e))
  },

  beforeDestroy() {
    this.setProduct(null);
    document.removeEventListener('keydown', this.onKeyDown.bind(this));
  }
}
</script>
